import * as React from "react";
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  required,
  BooleanInput,
  DateTimeInput
} from "react-admin";
import { worldChoices } from "./worlds";

const imbuementSlotsOptions = [{ id: 'helmet', name: 'Helmet' }, { id: 'armor', name: 'Armor' }, { id: 'weapon', name: 'Weapon' }]

const CharacterEdit = (props) => {

  return (
    <Edit
      {...props}
    >
      <SimpleForm >
        <NumberInput source="id" disabled />
        <TextInput
          source="name"
          required
        />
        <ReferenceInput reference="accounts" source="account.id" target="account" required >
          <SelectInput
            optionText="email"
            validate={required()}
          />
        </ReferenceInput>
        <TextInput
          source="characterId"
          required
        />
        <NumberInput
          source="characterListId"
          required
          defaultValue={1}
        />
        <NumberInput
          source="level"
          required
        />
        <BooleanInput source="isActive" />
        <BooleanInput source="allowShortSchedule" />
        <ReferenceInput reference="scripts" source="script.id" required >
          <SelectInput
            optionText="name"
            validate={required()}
          />
        </ReferenceInput>
        <SelectInput
          source="status"
          choices={[{ id: 'logged_out', name: 'Logged out' }, { id: 'logged_in', name: 'Logged in' }]}
          validate={required()}
        />
        <SelectInput
          source="world"
          choices={worldChoices}
          validate={required()}
        />
        <NumberInput
          source="goldBalance"
          defaultValue={0}
          disabled
        />
        <TextInput
          source="imbuements"
          defaultValue={''}
          multiline
          fullWidth
        />
        <SelectArrayInput
          source="enabledImbuementsSlots"
          choices={imbuementSlotsOptions}
        />
        <DateTimeInput
          source="lastSessionScheduledAt" />
      </SimpleForm>
    </Edit>
  );
};

export default CharacterEdit;
