import {
  Datagrid,
  TextField,
  List,
  DateField,
  ReferenceInput,
  SelectInput,
} from "react-admin";

const typeChoices = [
  { id: 'general', name: 'General' },
  { id: 'session_balance', name: 'Session balance' },
  { id: 'stuck', name: 'Stuck' }
]

const LogFilters = [
  <ReferenceInput labal="Character" reference="characters" source="characterId" target="id"
  >
    <SelectInput
      optionText="name"
    />
  </ReferenceInput >,
  < SelectInput source="type" choices={typeChoices} />
]

const LogList = () => {
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={LogFilters}
    >
      <Datagrid>
        <DateField source="date" showTime locales='pl' />
        <TextField source="character.name" />
        <TextField source="type" />
        <TextField source="value" />
      </Datagrid>
    </List>
  );
};

export default LogList;
