import * as React from "react";
import crudProvider from "ra-data-nestjsx-crud";
import { Admin, CustomRoutes, fetchUtils, Resource } from "react-admin";
import { Route } from "react-router-dom";
import authProvider from "./authProviderV2";
import Accounts from "./accounts";
import Characters from "./characters";
import { VncList } from "./vnc/VncList";
import { Vnc } from "./vnc/Vnc";
import { MyMenu } from "./Layout";
import Scripts from "./scripts";
import ScriptOverrides from "./script-overrides";
import Logs from "./logs";

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json", 'ngrok-skip-browser-warning': 'any' });
  }
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = crudProvider(
  process.env.REACT_APP_API_URL + '/api',
  (url, options) => httpClient(url, options)
);


const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      disableTelemetry
      requireAuth
      menu={MyMenu}
    >
      <Resource name="accounts" {...Accounts} />
      <Resource name="characters" {...Characters} />
      <Resource name="logs" {...Logs} />
      <Resource name="scripts" {...Scripts} />
      <Resource name="script-overrides" {...ScriptOverrides} />
      <CustomRoutes>
        <Route path="/vnc" element={<VncList />} />
        <Route path="/vnc/:port" element={<Vnc />} />
      </CustomRoutes>
    </Admin>
  )
};

export default App;
