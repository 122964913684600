import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

const CharacterEdit = (props) => {

  return (
    <Edit
      {...props}
    >
      <SimpleForm >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="data"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default CharacterEdit;
