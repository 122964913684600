import * as React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { worldChoices } from "./worlds";

const CharacterCreate = (props) => {

  return (
    <Create   {...props} >
      <SimpleForm >
        <TextInput
          source="name"
          required
        />
        <ReferenceInput reference="accounts" source="account" required >
          <SelectInput
            optionText="email"
            validate={required()}
          />
        </ReferenceInput>
        <TextInput
          source="characterId"
          required
        />
        <NumberInput
          source="characterListId"
          required
          defaultValue={1}
        />
        <NumberInput
          source="level"
          required
        />
        <BooleanInput source="isActive" defaultValue={false} />
        <BooleanInput source="allowShortSchedule" defaultValue={false} />
        <ReferenceInput reference="scripts" source="script" required >
          <SelectInput
            optionText="name"
            validate={required()}
          />
        </ReferenceInput>
        <SelectInput
          source="world"
          choices={worldChoices}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  );
};

export default CharacterCreate;
