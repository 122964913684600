export const worldChoices = [
    "Adra",
    "Alumbra",
    "Antica",
    "Ardera",
    "Astera",
    "Axera",
    "Bastia",
    "Batabra",
    "Belobra",
    "Bombra",
    "Bona",
    "Cadebra",
    "Calmera",
    "Castela",
    "Celebra",
    "Celesta",
    "Collabra",
    "Damora",
    "Descubra",
    "Dia",
    "Dibra",
    "Epoca",
    "Esmera",
    "Etebra",
    "Famosa",
    "Fera",
    "Ferobra",
    "Firmera",
    "Gentebra",
    "Gladera",
    "Gravitera",
    "Harmonia",
    "Havera",
    "Honbra",
    "Illusera",
    "Impulsa",
    "Inabra",
    "Issobra",
    "Jacabra",
    "Kalibra",
    "Kardera",
    "Karna",
    "Libertabra",
    "Lobera",
    "Luminera",
    "Lutabra",
    "Marbera",
    "Marcia",
    "Menera",
    "Monza",
    "Mudabra",
    "Mykera",
    "Nadora",
    "Nefera",
    "Nossobra",
    "Ocebra",
    "Olima",
    "Ombra",
    "Optera",
    "Ousabra",
    "Pacera",
    "Peloria",
    "Premia",
    "Quelibra",
    "Quintera",
    "Refugia",
    "Reinobra",
    "Seanera",
    "Secura",
    "Serdebra",
    "Solidera",
    "Suna",
    "Syrena",
    "Talera",
    "Tembra",
    "Thyria",
    "Trona",
    "Utobra",
    "Venebra",
    "Versa",
    "Visabra",
    "Vitera",
    "Vunira",
    "Wintera",
    "Wizera",
    "Xandebra",
    "Yonabra",
    "Zenobra",
    "Zuna",
    "Zunera"
].map(world => ({
    id: world, name: world
}))
