import React from 'react';
import { VncScreen } from 'react-vnc';
import { useParams } from 'react-router-dom'

export const Vnc = () => {
    let { port } = useParams();
    return (
        <VncScreen
            url={`wss://${port}.sysupapp.win/websockify`}
            scaleViewport
            background="#fafafb"
            style={{
                width: '100vw',
                height: '80vh',
            }}
        />
    );
}