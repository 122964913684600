import ScriptCreate from "./ScriptCreate";
import ScriptEdit from "./ScriptEdit";
import ScriptList from "./ScriptList";

const Scripts = {
  list: ScriptList,
  create: ScriptCreate,
  edit: ScriptEdit,
};

export default Scripts;
