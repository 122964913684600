import ScriptOverrideCreate from "./ScriptOverrideCreate";
import ScriptOverrideEdit from "./ScriptOverrideEdit";
import ScriptOverrideList from "./ScriptOverrideList";

const ScriptOverrides = {
  list: ScriptOverrideList,
  create: ScriptOverrideCreate,
  edit: ScriptOverrideEdit,
};

export default ScriptOverrides;
