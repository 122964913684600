import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput
} from "react-admin";

const AccountEdit = (props) => {

  return (
    <Edit
      {...props}
    >
      <SimpleForm >
        <TextInput
          source="email"
          required
        />
        <TextInput
          source="password"
        />
        <TextInput
          source="macAddress"
          required
        />
        <NumberInput
          source="port"
          required
        />
        <BooleanInput
          source="isActive"
        />
      </SimpleForm>
    </Edit>
  );
};

export default AccountEdit;
