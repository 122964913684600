import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from "react-admin";

const CharacterCreate = (props) => {

  return (
    <Create {...props} >
      <SimpleForm >
        <TextInput
          source="name"
          required
        />
        <TextInput
          source="data"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default CharacterCreate;
