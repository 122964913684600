import * as React from "react";
import { Typography, Link } from '@mui/material';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput
} from "react-admin";

const AccountCreate = () => {

  return (
    <Create    >
      <SimpleForm >
        <TextInput
          source="email"
          required
        />
        <TextInput
          source="password"
          required
        />
        <Typography >
          <Link href="https://mac-address.alldatafeeds.com/mac-address-generator">
            Mac Address Generator
          </Link>
        </Typography>
        <TextInput
          source="macAddress"
          required
        />
        <NumberInput
          source="port"
          helperText="Unique port in range 5900-6000"
          required
        />
        <BooleanInput
          source="isActive"
        />
      </SimpleForm>
    </Create>
  );
};

export default AccountCreate;
