import {
  Datagrid,
  TextField,
  List,
  EditButton,
} from "react-admin";

const ScriptList = () => {
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default ScriptList;
