import * as React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  TextField,
} from "react-admin";

const ScriptOverrideEdit = (props) => {
  return (
    <Edit {...props} >
      <SimpleForm >
        <TextInput
          source="id"
          disabled
        />
        <TextInput
          source="name"
          required
        />
        <ReferenceInput reference="scripts" source="script.id" required >
          <SelectInput
            optionText="name"
            validate={required()}
          />
        </ReferenceInput>
        <NumberInput
          source="minLvl"
          required
        />
        <NumberInput
          source="maxLvl"
          required
        />
        <TextInput
          source="data"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default ScriptOverrideEdit;
