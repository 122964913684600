import React from 'react';
import { Link, useRecordContext, List, Datagrid, TextField } from 'react-admin';

const VncLink = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Link to={`/vnc/${record.account.port}`}>VNC</Link>
};

export const VncList = () => {
    return (
        <List resource='characters'
            sort={{ field: 'id', order: 'DESC' }}
            filter={{ status: 'logged_in' }}
            hasCreate={false}
            title={"Streams"}
            empty={false}
        >
            <Datagrid>
                <TextField source="account.email" />
                <TextField source="name" />
                <VncLink />
            </Datagrid>
        </List>
    );
}