import { jwtDecode } from "./utils/jwt";

const authProvider = {
    // send username and password to the auth server and get back credentials
    login: params => {
        const { username, password } = params;
        const request = new Request(process.env.REACT_APP_API_URL + "/api/v1/auth/admin/email/login", {
            method: "POST",
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ "Content-Type": "application/json" }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem("token", token);
            });
    },
    // when the dataProvider returns an error, check if this is an authentication error
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // when the user navigates, make sure that their credentials are still valid
    checkAuth: params => {
        return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
    },
    // remove local credentials and notify the auth server that the user logged out
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    // get the user's profile
    getIdentity: () => Promise.reject({
        id: 'user',
        fullName: 'John Doe',
    }),
    // get the user permissions (optional)
    getPermissions: () => {
        const token = localStorage.getItem("token");
        // console.log({
        //     sth: [jwtDecode(token).role]
        // })
        const roles = token ? [jwtDecode(token).role] : []
        return Promise.resolve({ permissions: roles });
    },
}

export default authProvider;
