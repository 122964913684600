import * as React from "react";
import { Menu } from "react-admin";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import DescriptionIcon from '@mui/icons-material/Description';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import ListIcon from '@mui/icons-material/List';

export const MyMenu = (props) => {
    return (
        <Menu {...props}>
            <Menu.Item to="/accounts" leftIcon={<AccountBalanceWalletIcon />} />
            <Menu.Item to="/characters" leftIcon={<SupervisorAccountIcon />} />
            <Menu.Item to="/logs" leftIcon={<ListIcon />} />
            <Menu.Item to="/scripts" leftIcon={<DescriptionIcon />} />
            <Menu.Item to="/script-overrides" leftIcon={<DashboardCustomizeIcon />} />
            <Menu.Item to="/vnc" leftIcon={<ConnectedTvIcon />} />
        </Menu>
    )
};
