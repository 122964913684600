import * as React from "react";
import {
  Create,
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from "react-admin";

const ScriptOverrideCreate = (props) => {
  return (
    <Create {...props} >
      <SimpleForm >
        <TextInput
          source="name"
          required
        />
        <ReferenceInput reference="scripts" source="script" required >
          <SelectInput
            optionText="name"
            validate={required()}
          />
        </ReferenceInput>
        <NumberInput
          source="minLvl"
          required
        />
        <NumberInput
          source="maxLvl"
          required
        />
        <TextInput
          source="data"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default ScriptOverrideCreate;
