import {
  Datagrid,
  TextField,
  List,
  EditButton,
} from "react-admin";

const CharacterList = () => {

  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="goldBalance" />
        <TextField source="account.email" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CharacterList;
