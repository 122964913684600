import CharacterCreate from "./CharacterCreate";
import CharacterEdit from "./CharacterEdit";
import CharacterList from "./CharacterList";

const Characters = {
  list: CharacterList,
  create: CharacterCreate,
  edit: CharacterEdit,
};

export default Characters;
