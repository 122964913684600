import AccountCreate from "./AccountCreate";
import AccountEdit from "./AccountEdit";
import AccountList from "./AccountList";

const Accounts = {
  list: AccountList,
  create: AccountCreate,
  edit: AccountEdit,
};

export default Accounts;
